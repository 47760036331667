<template>
  <b-row>
    <b-col sm="4">
      <!-- Contact and Inbox -->
      <b-row
        v-if="!isLoginAs"
        class="mb-2"
      >
        <b-col
          sm="8"
          class="mb-2 mb-sm-0"
        >
          <b-button
            type="button"
            variant="primary"
            class="w-100 py-8px"
            size="sm"
            @click="onContactChampion"
          >
            <feather-icon
              icon="MailIcon"
              size="16"
              class="mr-50"
            />
            Contact the program manager
          </b-button>
        </b-col>
        <b-col sm="4">
          <b-button
            type="link"
            variant="primary"
            class="w-100 py-8px"
            size="sm"
            :to="{ name: 'participant-messages' }"
          >
            <feather-icon
              icon="MessageSquareIcon"
              size="16"
              class="mr-50"
            />
            Inbox
          </b-button>
        </b-col>
      </b-row>

      <!-- Quick links -->
      <b-card v-if="displayQuickLinks.length > 0">
        <b-col
          sm="12"
          class="d-flex align-items-center mb-2 p-0"
        >
          <b-avatar
            square
            size="40"
            variant="light-success"
            class="mr-1"
          >
            <feather-icon
              icon="LinkIcon"
              size="25"
            />
          </b-avatar>
          <b-card-title class="mb-0">
            Quick links
          </b-card-title>
        </b-col>
        <ul class="pl-2">
          <li
            v-for="(link, index) in displayQuickLinks"
            :key="index"
          >
            <b-link
              :to="link.route"
              :title="link.title"
            >
              <span v-html="link.title" />
            </b-link>
          </li>
        </ul>
      </b-card>

      <!-- Timeline -->
      <b-card class="d-none d-sm-block">
        <h4 class="mb-2">
          <b-row>
            <b-col class="d-flex align-items-center">
              <feather-icon
                icon="ListIcon"
                size="20"
              />
              <b-card-title class="mb-0 ml-2">
                Key dates
              </b-card-title>
            </b-col>
          </b-row>
        </h4>
        <key-dates
          :timezone="programTimezone"
          :key-dates="keyDates"
        />
      </b-card>
    </b-col>

    <b-col
      v-if="isApplicationAnswersLoading"
      sm="8"
    >
      <aom-skeleton-loader
        :loader-type="loaderTypes.SHORT"
      />
    </b-col>
    <b-col
      v-else
      sm="8"
    >
      <!-- Applications -->
      <b-card
        v-for="(link, index) in applicationQuickLink"
        :key="index"
      >
        <b-row>
          <b-col
            sm="4"
            class="d-flex align-items-center mb-1 mb-sm-0"
          >
            <b-avatar
              square
              size="40"
              variant="light-success"
              class="mr-1"
            >
              <feather-icon
                icon="FileTextIcon"
                size="25"
              />
            </b-avatar>
            <b-card-title class="mb-0">
              {{ link.title }}
            </b-card-title>
          </b-col>
          <b-col
            sm="4"
            class="d-flex align-items-center mb-1 mb-sm-0"
          >
            <b-progress
              :value="link.completePercent"
              class="w-100 mr-1"
              :class="
                link.completePercent > 50 ?
                  'default-green-chart-color' :
                  'default-chart-color'
              "
            />
            <span>{{ link.completePercent }}%</span>
          </b-col>

          <b-col
            sm="4"
            class="d-flex align-items-center"
          >
            <b-button
              type="link"
              variant="primary"
              :to="link.route"
              class="w-100"
            >
              {{ link.completePercent === 100 ? "Update application" : "Complete application" }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col
          v-for="(tile, index) in displayTiles"
          :key="index"
          sm="4"
        >
          <b-card>
            <b-row
              align-v="center"
              class="d-none d-sm-flex"
            >
              <b-col
                sm="3"
                class="pr-0 mr-1"
              >
                <b-avatar
                  square
                  size="40"
                  variant="light-success"
                >
                  <feather-icon
                    :icon="tile.icon"
                    size="25"
                  />
                </b-avatar>
              </b-col>
              <b-col
                sm="8"
                class="pl-0"
              >
                <b-card-title class="text-truncate m-0">
                  {{ tile.title }}
                </b-card-title>
                <p class="font-small-3 m-0">
                  {{ tile.description }}
                </p>
                <p class="font-small-3 font-weight-bold m-0">
                  {{ tile.value }}
                </p>
              </b-col>
            </b-row>

            <!-- Card content for Mobile -->
            <b-row
              align-v="center"
              class="d-flex d-sm-none flex-nowrap"
            >
              <b-col
                sm="3"
                class="pr-0 mr-1 w-auto"
              >
                <b-avatar
                  square
                  size="40"
                  variant="light-success"
                >
                  <feather-icon
                    :icon="tile.icon"
                    size="25"
                  />
                </b-avatar>
              </b-col>
              <b-col
                sm="8"
                class="pl-0"
              >
                <b-card-title class="text-truncate m-0">
                  {{ tile.title }}
                </b-card-title>
                <p class="font-small-3 m-0">
                  {{ tile.description }}
                </p>
                <p class="font-small-3 font-weight-bold m-0 d-none d-sm-block">
                  {{ tile.value }}
                </p>
              </b-col>
              <b-col style="transform: translateY(-50%)">
                <p class="font-small-3 font-weight-bold m-0">
                  {{ tile.value }}
                </p>
              </b-col>
            </b-row>
            <b-card-text class="mt-2 text-center">
              <b-button
                v-if="tile.buttonLink"
                class="w-100"
                type="link"
                :to="tile.buttonLink"
                variant="primary"
              >
                {{ tile.buttonText }}
              </b-button>
              <b-button
                v-if="tile.name === 'showMyAvailability'"
                class="w-100"
                variant="primary"
                @click="showMyAvailability"
              >
                {{ tile.buttonText }}
              </b-button>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-col>

    <b-col class="d-flex d-sm-none">
      <!-- Timeline for mobile -->
      <b-card class="w-100">
        <h4 class="mb-2">
          <b-row>
            <b-col class="d-flex align-items-center">
              <feather-icon
                icon="ListIcon"
                size="20"
              />
              <b-card-title class="mb-0 ml-2">
                Key dates
              </b-card-title>
            </b-col>
          </b-row>
        </h4>
        <key-dates
          :timezone="programTimezone"
          :key-dates="keyDates"
        />
      </b-card>
    </b-col>
    <my-availibility
      ref="myAvailbilityModal"
      :user="profile"
    />
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardText,
  BButton,
  BLink,
  BProgress,
  BAvatar
} from "bootstrap-vue";
import KeyDates from "@/views/components/common/KeyDates.vue";
import { makeErrorToast } from "@/libs/utils";
import { loaderTypes, programTypes, userRoles } from '@models';
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { mapGetters } from "vuex";
import MyAvailibility from '@/participants/mentoring-on-demand/partials/MyAvailibility.vue';
export default {
  name: 'ParticipantDashboard',
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BButton,
    KeyDates,
    BLink,
    AomSkeletonLoader,
    BProgress,
    BAvatar,
    MyAvailibility
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      for (let r of vm.profile.roles) {
        if (r === userRoles.MENTOR || r === userRoles.MENTEE) {
          await vm.loadApplicationList(r);
        }  
      }

      if (
        !vm.applications || 
      vm.applications?.mentor?.completed_percent > 0 || 
      vm.applications?.mentee?.completed_percent > 0) {
        return;
      }
      if(vm.isUserMentor) {
        const questionId = [...vm.applicationMentor?.questions][0]?.id;
        return vm.$router.replace({ 
          name : 'participant-application-mentor', 
          params: { role: userRoles.MENTOR , questionId }, 
          query: {'enrolement' : true}
        });
      }

      if(vm.isUserMentee) {
        const questionId = [...vm.applicationMentee?.questions][0]?.id;
        return vm.$router.replace({ 
          name : 'participant-application-mentee', 
          params: { role: userRoles.MENTEE , questionId }, 
          query: {'enrolement' : true}
        });
      }
    });
  },
  computed: {
    ...mapGetters("participants", ['isApplicationAnswersLoading', 'applicationMentee', 'applicationMentor']),
    ...mapGetters('profile', ['isUserMentee','isUserMentor' ]),
    isLoginAs () {
      const userData = this.profile;
      if (userData?.logged_in_by && Number(userData?.logged_in_by.id) !== Number(userData.id)) {
        return true;
      }
      return false;
    },
    appCurrentProgram() {
      return this.$store.getters['app/currentProgram'];
    },

    program() {
      return this.$store.getters['participants/program'];
    },

    programTimezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },

    keyDates() {
      if (this.appCurrentProgram && this.appCurrentProgram.key_dates) {
        return this.appCurrentProgram.key_dates
          .filter(date => date.is_public || date.id === undefined)
          .sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);

            return dateA.getTime() - dateB.getTime();
          });
      }
      return [];
    },

    programs() {
      return this.$store.getters['participants/programs'];
    },

    applications() {
      return this.$store.getters['participants/applications'];
    },

    matches() {
      return this.$store.getters['participants/matches'];
    },

    matches_request_count() {
      return this.$store.getters['participants/matches_request_count'];
    },

    surveys() {
      return this.$store.getters['participants/surveys'];
    },

    trainings() {
      return this.$store.getters['participants/trainings'];
    },

    resources() {
      return this.$store.getters['participants/resources'];
    },

    participants() {
      return this.$store.getters['participants/participants'];
    },

    messages() {
      return this.$store.getters['participants/messages'];
    },

    profile() {
      return this.$store.getters['profile/profile'];
    },
    mentorDisplay () {
      return this.$store.getters['participants/mentorDisplay'];
    },
    menteeDisplay () {
      return this.$store.getters['participants/menteeDisplay'];
    },
    haveBothRoles() {
      return this.profile.roles.includes(userRoles.MENTOR) && this.profile.roles.includes(userRoles.MENTEE);
    },
    isUserMentee() {
      return this.profile.roles.includes(userRoles.MENTEE); 
    },
    isUserMentor() {
      return this.profile.roles.includes(userRoles.MENTOR); 
    },
    isProgramMentoringOnDemand () {
      return this.program && this.program.type_id === programTypes.MENTORING_ON_DEMAND;
    },
    isProgramPartnerPreferencing () {
      return this.program && this.program.type_id === programTypes.PARTNER_PREFERENCING;
    },
    // @TODO this is old code from Pv1
    displayQuickLinks () {
      let links = [];

      // Mentor Application
      if (this.applications && this.applications.mentor && this.applications.mentor.uncomplete_percent !== 0) {
        links = [
          ...links,
          {
            title: `You have <b>${this.applications.mentor.uncomplete_percent}% of ${this.haveBothRoles ? `your ${this.mentorDisplay}` : "your"} application</b> to complete`,
            route: { name: 'participant-application-mentor-overview' },
          }
        ];
      }

      // Mentee Application
      if (this.applications && this.applications.mentee && this.applications.mentee.uncomplete_percent !== 0) {
        links = [
          ...links,
          {
            title: `You have <b>${this.applications.mentee.uncomplete_percent}% of ${this.haveBothRoles ? `your ${this.menteeDisplay}` : "your"} application</b> to complete`,
            route: { name: 'participant-application-mentee-overview' },
          }
        ];
      }

      // Key Events
      if (this.programs && this.programs.events_comming_up && this.programs.events_comming_up.length) {
        links = [
          ...links,
          {
            route: null,
            title: `You have ${programs.events_comming_up.length} ${programs.events_comming_up.length > 1 ? "events" : "event"}`,
          }
        ];
      }

      // Your mentee
      if ((this.matches && this.matches.total > 0) && (this.matches && this.matches.your_mentee && this.matches.your_mentee.length)) {
        links = [
          ...links,
          {
            title: `Your ${this.menteeDisplay} is <b>${this.matches.your_mentee.map(mentee => mentee.user.full_name).join(', ')}</b>`,
            route: { name: 'participant-my-matches' },
          }
        ];
      }

      // Your mentor
      if ((this.matches && this.matches.total > 0) && (this.matches && this.matches.your_mentor && this.matches.your_mentor.length)) {
        links = [
          ...links,
          {
            title: `Your ${this.mentorDisplay} is <b>${this.matches.your_mentor.map(mentor => mentor.user.full_name).join(', ')}</b>`,
            route: { name: 'participant-my-matches' },
          }
        ];
      }

      // Training
      if (this.trainings && this.trainings.uncomplete > 0) {
        links = [
          ...links,
          {
            title: `You have <b>${this.trainings.uncomplete} incomplete</b> trainings`,
            route: { name: 'participant-training-list' },
          }
        ];
      }

      // Surveys
      if (this.surveys && this.surveys.uncomplete > 0) {
        links = [
          ...links,
          {
            title: `You have <b>${this.surveys.uncomplete} incomplete</b> surveys`,
            route: { name: 'participant-surveys' },
          }
        ];
      }

      // Unread messages
      if (this.messages && this.messages.unread > 0 && !this.isLoginAs) {
        links = [
          ...links,
          {
            title: `You have <b>${this.messages.unread} unread</b> messages`,
            route: { name: 'participant-messages' },
          }
        ];
      }

      return links;
    },

    applicationQuickLink() {
      let links = [];

      // Mentor Application Unanswered
      if (this.applications && this.applications.mentor) {
        links = [
          ...links,
          {
            title: `${this.mentorDisplay} application`,
            completePercent: this.applications.mentor.completed_percent,
            route: { name: 'participant-application-mentor-overview' },
          }
        ];
      }

      if (this.applications && this.applications.mentee) {
        links = [
          ...links,
          {
            title: `${this.menteeDisplay} application`,
            completePercent: this.applications.mentee.completed_percent,
            route: { name: 'participant-application-mentee-overview' },
            // title: `You have ${this.haveBothRoles ? `a ${this.menteeDisplay}` : "an"} application to be completed - ${this.applications.mentee.uncomplete_percent} left to complete.`
          }
        ];
      }

      return links;
    },

    displayTiles () {
      let tiles = [];
      // Match
      if (this.matches && this.matches.total > 0) {
        tiles = [
          ...tiles,
          {
            title: `My Match`,
            value: this.matches.total,
            description: `Number of matches`,
            buttonText: 'View matches',
            buttonLink: { name: 'participant-my-matches' },
            icon : 'UsersIcon'
          }
        ];
      }

      // Training
      if (this.trainings && this.trainings.total > 0 && !this.program.training_disabled) {
        tiles = [
          ...tiles,
          {
            title: `Training`,
            description: "Training to complete",
            value: `${this.trainings.completed}/${this.trainings.total}`,
            buttonText: 'Complete training',
            buttonLink: { name: 'participant-training-list' },
            icon : 'BookIcon'
          }
        ];
      }

      // Surveys
      if (this.surveys && this.surveys.total > 0) {
        tiles = [
          ...tiles,
          {
            title: `Surveys`,
            description: "Survey to complete",
            value: this.surveys.value,
            buttonText: 'Complete surveys',
            buttonLink: { name: 'participant-surveys' },
            icon : 'Edit3Icon'
          }
        ];
      }

      // Resources
      if (this.resources && this.resources.total > 0) {
        tiles = [
          ...tiles,
          {
            title: `Resources`,
            description: "Resources available",
            value: this.resources.total,
            buttonText: 'View resources',
            buttonLink: { name: 'participant-resources' },
            icon : 'BookOpenIcon'
          }
        ];
      }

      // Other programs
      if (this.programs && this.programs.total > 1) {
        tiles = [
          ...tiles,
          {
            title: `Access Your Other Programs`,
            description: this.programs.content,
            buttonText: 'View All Programs',
            buttonLink: { name: 'participant-my-programs' },
            icon : 'UsersIcon'
          }
        ];
      }

      // Participants
      if (this.program && this.program.participant_visibility && this.participants && this.participants.total > 0) {
        tiles = [
          ...tiles,
          {
            title: `Participants`,
            description: "Program participants",
            value: this.participants.total,
            buttonText: 'View participants',
            buttonLink: { name: 'participant-participants' },
            icon : 'UserIcon'
          }
        ];
      }
      if (this.isProgramMentoringOnDemand && this.isUserMentee) {
        tiles = [
          ...tiles,
          {
            title: `Find a Match`,
            description: "Quick match",
            buttonText: 'View All',
            buttonLink: { name: 'participant-find-a-match' },
            icon : 'UsersIcon'
          }
        ];
      }
      if (this.isProgramMentoringOnDemand && this.isUserMentor) {
        tiles = [
          ...tiles,
          {
            title: `Match Request`,
            description: `You have ${this.matches_request_count} match requests`,
            buttonText: 'View All',
            buttonLink: { name: 'participant-match-requests' },
            icon : 'UsersIcon'
          }
        ];
      }
      if (this.isProgramMentoringOnDemand && this.isUserMentor) {
        tiles = [
          ...tiles,
          {
            title: `My Availability`,
            description: `${this.availabilityStatus}`,
            buttonText: 'Change',
            name: 'showMyAvailability',
            icon : 'ClockIcon'
          }
        ];
      }

      if (this.isProgramPartnerPreferencing && this.isUserMentee && this.matches.total < 1 && this.program.partner_pref_active) {
        tiles = [
          ...tiles,
          {
            title: `Partner Preferencing`,
            description: `Select your preferred mentors`,
            buttonText: 'Complete Preferences',
            buttonLink: { name: 'participant-partner-preferencing' },
            icon : 'HandIcon'
          }
        ];
      }

      return tiles;
    },

    applicationSetId() {
      const program = this.$store.getters['participants/program'];
      if (program && program.application_set) {
        return program.application_set.id;
      }
      return undefined;
    },
    availabilityStatus() {
      return this.$store.getters['profile/profile'].isInUnavailabilityPeriod ? 'Unavailable' : 'Available';
    }
  },
  methods: {
    handleClickQuickLink (type) {
      if (type === 'events_coming_up') {
        if (this.programs.events_comming_up_quicklink && this.programs.events_comming_up_quicklink.isHighlight) {

          this.updateClickedAt('events_coming_up', this.programs.events_comming_up_quicklink);
        }

        this.$refs.eventCommingModel.open();
      }

      if (type === 'my_match') {
        if (this.matches.my_match_quicklink && this.matches.my_match_quicklink.isHighlight) {

          this.updateClickedAt('my_match', this.matches.my_match_quicklink);
        }
      }

      if (type === 'your_mentor') {
        if (this.matches.your_mentor_quicklink && this.matches.your_mentor_quicklink.isHighlight) {

          this.updateClickedAt('your_mentor', this.matches.your_mentor_quicklink);
        }
      }

      if (type === 'your_mentee') {
        if (this.matches.your_mentee_quicklink && this.matches.your_mentee_quicklink.isHighlight) {

          this.updateClickedAt('your_mentee', this.matches.your_mentee_quicklink);
        }
      }
    },

    updateClickedAt (){},

    isShowMyMatchAlert () {
      return this.matches.my_match_quicklink && this.matches.my_match_quicklink.isHighlight;
    },

    async loadApplicationList(role) {
      if (!this.program) {return false;}
      try {
        let ownApplication = await this.$store.dispatch("participants/FETCH_OWN_APPLICATION_SETS", {
          programId: this.program.id,
          applicationSetId: this.applicationSetId,
          role: role
        });

        if (ownApplication) {
          await this.$store.dispatch("participants/FETCH_OWN_APPLICATION_ANSWERS", {
            programId: this.program.id,
            formId: ownApplication.id,
            userId: this.profile.id
          },
          true
          );
        }
      } catch (e) {
        this.$toast(makeErrorToast("Application list not loaded."));
        this.$log.error(e);
      }
    },

    onContactChampion() {
      this.$router.push({name: this.$route.name, query: {isContactProgramManager: 1}});
    },
    showMyAvailability() {
      this.$refs.myAvailbilityModal.show();
    }
  },
  setup() {
    return {
      loaderTypes
    };
  },
};
</script>

<style lang="scss" scoped>
.h-50px {
  height: 50px;
}
.py-8px {
  padding-top: 8px;
  padding-bottom: 8px;
}
::v-deep .default-chart-color {
  .progress-bar {
    background-color: #FFA500 !important;
  }
}
::v-deep .default-green-chart-color {
  .progress-bar {
    background-color: #9bcc65 !important;
  }
}
</style>